<template>
<div class="custom-width">
    <template v-if="cartlist.length == 0">
        <div class="nomore">
            <image src="../../static/empty.png" mode="widthFix"></image>
            <div>您尚未添加该店铺商品至购物车</div>
        </div>
    </template>
    <div v-for="(sp_item,sp_index) in cartlist" :key="sp_index" class="shop-wrp">
        <div class="shop-tit" v-if="cartlist.length > 0">
            {{sp_item.Merchant_Model.Merchant_Name}}
        </div>

        <div class="goodInfos" v-for="(item,index) in sp_item.Product_List" :key="index">
            <img class="goodsImgs" :src="item.Product_ImageUrl?domin+item.Product_ImageUrl:'../../static/standImg.jpg'" mode="scaleToFill" />
            <div class="goodsinfocontent">
                <div class="goodTit-wrp">
                    <div class="goodTit">
                        {{item.Product_Name}}
                    </div>
                    <div class="good-tit-min">
                        {{item.Stock_Color}} {{item.Stock_Size}} {{item.Stock_Variety}}
                    </div>
                </div>
                <div class="goodsCheck">
                    <div class="goodsPrice">
                        ¥ <text>{{item.Stock_SellPrice}}</text>
                    </div>
                    <div class="cartCount">
                        <i class="el-icon-minus" color="#f97a0a" size="25" @click="countMinus(item.Cart_ID,index,sp_index)">
                        </i>
                        <text>{{item.Cart_Count}}</text>
                        <i class="el-icon-plus" color="#f97a0a" size="25" @click="countPlus(item.Cart_ID,index,sp_index)">
                        </i>
                    </div>
                </div>
            </div>
            <i class="el-icon-close" type="closeempty" color="#f97a0a" size="20" @click="countdelt(item.Cart_ID,index,sp_index)"></i>
        </div>
    </div>
    <div class="Btmwrp">
        <text>¥{{total}}</text>
        <button type="default" class="btn" @click="getBuyOrder">确定购买</button>
    </div>
</div>
</template>

<script>
let app = null;
export default {
    data() {
        return {
            cartlist: [],
            sid: 0,
            total: 0,
            domin: "https://www.visasiv.cn",
            confirmOpt: {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }
        }
    },
    created() {
        app = this.$.appContext.app
        if (sessionStorage.getItem("loginState") != 1) {
            this.$message("请先登录")
            this.$router.push("/login")
        }
        this.getCartList()
    },
    methods: {
        countMinus(cid, index, sp_index) {
            if (this.cartlist[sp_index].Product_List[index].Cart_Count > 0) {
                this.countChange(cid, index, sp_index, 'minus')
            } else if (this.cartlist[sp_index].Product_List[index].Cart_Count == 0) {
                this.countdelt(cid, index, sp_index)
            }
        },
        countPlus(cid, index, sp_index) {
            this.countChange(cid, index, sp_index, 'add')
        },
        countChange(cid, index, sp_index, way) {
            let tempNum = this.cartlist[sp_index].Product_List[index].Cart_Count
            if (way == 'minus') {
                tempNum -= 1;
            } else {
                tempNum += 1;
            }

            app.wpost({
                url: '/Ajax/WordWelfare/changecount',
                data: {
                    id: cid,
                    count: tempNum,
                },
                succ: (data) => {
                    this.cartlist[sp_index].Product_List[index].Cart_Count = tempNum
                    console.log("countChange:", data)
                    this.total = this.setTotal(this.cartlist)
                }
            })
        },
        countdelt(cid, index, sp_index) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', this.confirmOpt).then(() => {
                app.wpost({
                    url: '/Ajax/WordWelfare/deletecart',
                    data: {
                        id: cid,
                    },
                    succ: (data) => {
                        console.log("countChange:", data)
                        this.cartlist[sp_index].Product_List.splice(index, 1)
                        this.total = this.setTotal(this.cartlist)
                    }
                })
            })

        },
        getBuyOrder() {
            if (this.cartlist.length == 0) {
                this.$message("请先添加您需购买的产品")
                return false
            }
            // 购物车生成订单
            app.wpost({
                url: '/Ajax/WordWelfare/createordersfromcart',
                data: {},
                succ: (data) => {
                    this.$router.push({
                        path: '/cartInBuy/' + data.ordersid
                    })
                }
            })
        },
        getCartList() {
            app.wpost({
                url: '/Ajax/WordWelfare/getcartlist',
                data: {},
                succ: (data) => {
                    console.log("cartList:", data)
                    this.cartlist = data.list;
                    this.total = this.setTotal(data.list)
                }
            })
        },
        setTotal(arr) {
            let wholeMoney = 0
            arr.forEach(item => {
                let total = 0
                item.Product_List.forEach(pitem => {
                    total += pitem.Stock_SellPrice * pitem.Cart_Count
                })

                wholeMoney += total

            })
            return wholeMoney
        }
    }
}
</script>

<style scoped>
.goodInfos i {
    cursor: pointer;
}

.shop-wrp {
    border-radius: 6px;
    overflow: hidden;
    margin: 8px 5px;
}

.shop-tit {
    padding: 12px 6px;
    background-color: #fff;
    border-bottom: 1px solid #dedede;
    font-weight: bold;
}

.deltbtn {
    position: absolute;
    top: 0;
    right: 12px;
}

.good-tit-min {
    font-size: 13px;
    padding: 3px 0;
    margin-left: 8px;
}

.goodTit {
    font-weight: bold;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.goodsCheck {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.nomore {
    margin-top: 20px;
    color: #999;
    font-size: 13px;
    text-align: center;
}

.nomore image {
    width: 70px;
    height: 70px;
    margin: 12px;
}

.sellNum {
    color: #999;
    font-size: 12px;
}

.goodsPrice {
    margin: 6px 0;
    color: #e62d56;
    font-size: 13px;
}

.goodsPrice text {
    font-size: 20px;
    margin-left: 3px;
}

.goodsinfocontent {
    width: calc(100% - 90px)
}

.goodInfos {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 6px;
    border-bottom: 1px dashed #dedede;
    background-color: #fff;
}

.goodInfos:last-child {
    border: none;
}

.goodsImgs {
    width: 70px;
    height: 70px;
    margin-right: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.goodContent {
    height: calc(100% - 85px);
}

.goodTabs {
    margin: 0 12px;
    width: calc(100% - 24px);
    overflow: hidden;
    overflow-x: auto;
    padding-top: 6px;
    white-space: nowrap;
    height: 37px;
}

.goodTabs text {
    border: 1px solid #e5e5e5;
    background-color: #e5e5e5;
    color: #555;
    border-radius: 4px;
    padding: 6px;
    margin-right: 5px;
    font-size: 13px;

}

.goodTabs text.on {
    color: #f97a0a;
    background-color: #fde4d7;
    border-color: #fde4d7;
}

.cartCount {
    font-size: 17px;
    color: #333;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartCount text {
    margin: 0 8px;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp image {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.swichwrp switch {
    scale: 0.8;
}

.swichwrp {
    padding: 5px 0;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #ccc;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #f97a0a;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 16px;
    line-height: 2.1;
    padding-left: 1rem;
    box-sizing: border-box;
}

picker {
    width: 100%;
    font-size: 15px;
    padding-left: 1rem;
    height: 43px;
    box-sizing: border-box;
}

picker .uni-input {
    height: 43px;
    line-height: 43px;
}

.uni-input {
    height: 25px;
    line-height: 25px;
}

.tbInnr textarea {
    width: 100%;
    margin: 8px 0;
    border-radius: 5px;
    padding: 6px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.7;
}

.adrsWrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
}

.adrsWrp text {
    width: 31%;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;

}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
}

.tbftp {
    align-items: flex-start;
}

.Btmwrp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 89;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #333;

}

.Btmwrp text {
    margin-left: 12px;
    font-size: 30px;
}

.Btmwrp button {
    color: #fff;
    display: inline-block;
    background-color: #F97A0A;
    margin: 0;
    height: 47px;
    line-height: 47px;
    padding: 0 22px;
    border-radius: 22px;
    text-align: center;
    font-size: 15px;
}
</style>
